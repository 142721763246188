.ranking-list {
  position: fixed;
  height: 674px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #5328bb, #8046d2);
  border-radius: 24px 24px 0px 0px;
}

.ranking-list-item-rank {
  font-size: 18px;
  color: #ffbb10;
  padding-top: 15px;
}

.ranking-list-item-avatar {
  top: 16px;
  left: 6px;
}

.ranking-list-close {
  position: absolute;
  top: 26px;
  left: 16px;
}

.ranking-list-text {
  align-self: center;
  padding-top: 23px;
}

.ranking-list-item {
  margin-bottom: 10;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 6px 16px 16px 25px;
}

.ranking-list-item-left {
  flex: 1;
  margin-left: 18px;
  display: flex;
  flex-direction: row;
  height: 54px;
  position: relative;
  align-items: center;
}

.ranking-list-item-name {
  font-size: 16px;
  color: #ffffff;
  padding-left: 12px;
}

.ranking-list-item-description {
  color: #979797;
  font-size: 12px;
}

.ranking-list-item-container {
  display: flex;
  flex-direction: row;
}

.ranking-list-item-right {
  color: #f5db49;
  font-size: 16px;
}

.ranking-list-item-name {
  padding-left: 5px;
}

.ranking-list-empty {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  font-size: 15px;
}

.ranking-list-bottom {


  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 30px;
}

.ranking-list-bottom-text {
  font-size: 13px;
  color: #ffffff;
}

.ranking-list-bottom-flex {
  flex: 1;
  margin-left: 12px;
}

.ranking-list-bottom-flex-name {
  font-size: 13px;
  color: #ffffff;
}

.ranking-list-bottom-flex-count {
  padding-top: 6px;
  font-size: 13px;
  color: #f5db49;
}
@primary-color: #1DA57A;