.winning-record {
  height: 674px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #5328bb, #8046d2);
  border-radius: 24px 24px 0px 0px;
}
.header{
  display: flex;
  align-items:center;
  justify-content:center;
  position:fixed;
  top:0px;
  width:100vw;
  background:#5328bb;
  z-index: 10;
}

.winning-record-close {
  position: absolute;
  top: 26px;
  left: 16px;
}

.winning-record-text {
  align-self: center;
  padding-top: 23px;
  padding-bottom: 16px;
}

.winning-record-item {
  margin-bottom: 10;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 16px;
}

.winning-record-item-left {
  flex: 1;
}

.winning-record-item-description {
  color: #979797;
  font-size: 12px;
}

.winning-record-item-container {
  display: flex;
  flex-direction: row;
}

.winning-record-item-right {
  color: #ffffff;
  font-size: 15px;
}

.winning-record-item-name {
  padding-left: 5px;
}

.winning-record-empty {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  font-size: 15px;
}
.rule-page{
  background:#5328bb;
  padding-top: 30px;
  padding-bottom: 30px;
}

@primary-color: #1DA57A;