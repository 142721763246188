.wheel-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.wheel-container-base {
  background-image: url("../../assets/hua/low_bg.png");
  // background-image: url("../../assets/bg_zp_chuji@3x.png");
  background-size: cover;
  background-position: center;
}

.wheel-container-middle {
  background-image: url("../../assets/hua/center_bg.png");
    // background-image: url("../../assets/bg_zp_middle@3x.png");
  background-size: cover;
  background-position: center;
}

.wheel-container-advanced {
  // background-image: url("../../assets/bg_zp_gaoji@3x.png");
  background-image: url("../../assets/hua/height_bg.png");
  background-size: cover;
  background-position: center;
}

.more-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  position: "relative";
}

.more-welcome img {
  width: 240px;
  height: 64px;
}

.wheel-more {
  color: white;
  position: absolute;
  right: 16px;
  top: 33px;
}

.wheel-more img {
  width: 24px;
  height: 24px;
}

.tab-container {
  align-self: center;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.tab-item-left-active {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/btnbg_zp_chuji_left_s@3x.png");
  background-image: url("../../assets/hua/low_header.png");
  background-size: cover;
  width: 95px;
  height: 43px;
  padding-top: 7px;
  padding-left: 32px;
}

.tab-item-right-active {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/advanced@3x.png");
  // background-image: url("../../assets/btnbg_zp_gaoji_s@3x.png");
background-image: url("../../assets/hua/high_header.png");
  background-size: cover;
  width: 95px;
 height: 43px;
  padding-top: 7px;
  padding-left: 32px;

}

.tab-item-middle-active {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/btnbg_zp_middle_s@3x.png");
  background-image: url("../../assets/hua/center_header.png");
  background-size: cover;
  width: 95px;
 height: 43px;
  padding-top: 7px;
  padding-left: 32px;

}



.tab-item-middle {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/btnbg_zp_middle_n@3x.png");
  background-image: url("../../assets/hua/center_header.png");
  background-size: cover;
  width: 95px;
 height: 43px;
  padding-top: 7px;
  padding-left: 32px;
  opacity: 0.7;
}

.tab-item-right {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/btnbg_zp_gaoji_n@3x.png");
  background-image: url("../../assets/hua/high_header.png");
  background-size: cover;
  width: 95px;
 height: 43px;
  padding-top: 7px;
  padding-left: 32px;
  opacity: 0.7;
}

.tab-item-left {
  color: white;
  font-size: 16px;
  background-image: url("../../assets/hua/low_header.png");
  // background-image: url("../../assets/btnbg_zp_chuji_left_n@3x.png");
  background-size: cover;
  width: 95px;
 height: 43px;
  padding-top: 7px;
  padding-left: 32px;
  opacity: 0.7;
}

.star-add-container {
  position: absolute;
  top: 164px;
  left: 0px;
  z-index: 100;
}

.text-container {
  margin-top: 8px;
  margin-bottom: 2px;
}

.lucky-wheel-container {
  align-self: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin: 0 50px;
  margin-top: -12px;
  gap:12px
}

.buttons:last-child {
  margin-right: 0px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom {
  align-self: center;
  // margin-top: 10px;
  color: #ffc938;
  font-size: 13px;
  font-family: 'MyCustomFont', sans-serif;
}

.button {
  background-image: url("../../assets/hua/integral_btn.png");
  // background-image: url("../../assets/btnbg_zp_chuji_chouj_s@3x.png");
  background-size: 100% 110%;
  background-repeat: no-repeat;
  flex: 1;
  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
}

.disable-button {
  // background-image: url("../../assets/btnbg_zp_chuji_chouj_n@3x.png");
  background-image: url("../../assets/hua/integral_btn.png");
  // background-size: cover;
  background-size: 100% 110%;
  flex: 1;
  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
}

.button-advanced {
  // background-image: url("../../assets/btnbg_zp_gaoji_chouj_s@3x.png");
  background-image: url("../../assets/hua/integral_btn.png");
  background-size: 100% 110%;
  flex: 1;
  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
}

.button-middle {
  background-image: url("../../assets/hua/integral_btn.png");
    // background-size: cover;
    background-size: 100% 110%;
  flex: 1;
  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
      padding: 6px 0;
}



.disable-button-advanced {
  // background-image: url("../../assets/btnbg_zp_gaoji_chouj_n@3x.png");
  background-image: url("../../assets/hua/integral_btn.png");
  background-size: 100% 110%;
  flex: 1;
  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
}

.disable-button-middle {
  background-image: url("../../assets/hua/integral_btn.png");
    // background-size: cover;
    background-size: 100% 110%;
  flex: 1;
  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
}


.disable-button .count {
  color: #bf5076;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  opacity: 0.7;
 font-family: 'MyCustomFont', sans-serif;
}

.disable-button .label {
  background: linear-gradient(to right, #bf5076, #bf5076);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  opacity: 0.7;
  font-family: 'MyCustomFont', sans-serif;
}
// 高级文字
.disable-button-advanced .count {
  color: #bf5076;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  opacity: 0.7;
  font-family: 'MyCustomFont', sans-serif;
}

.disable-button-advanced .label {
  background: linear-gradient(to right, #bf5076, #bf5076);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  opacity: 0.7;
  font-family: 'MyCustomFont', sans-serif;
}

.button-advanced .count {
  color: #bf5076;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  font-family: 'MyCustomFont', sans-serif;
}

.button-advanced .label {
  background: linear-gradient(to right, #bf5076, #bf5076);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  font-family: 'MyCustomFont', sans-serif;
}

.button .count {
  color: #bf5076;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  font-family: 'MyCustomFont', sans-serif;
}

.button .label {
  background: linear-gradient(to right, #bf5076, #bf5076);
  // background: linear-gradient(to right, #f6f7f9, #ffe1e1)
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  font-family: 'MyCustomFont', sans-serif;
}
// 中级文字
.button-middle .count {
  color: #bf5076;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  font-family: 'MyCustomFont', sans-serif;
}

.button-middle .label {
  background: linear-gradient(to right, #bf5076, #bf5076);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  font-family: 'MyCustomFont', sans-serif;
}

.disable-button-middle .count {
  color: #bf5076;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  opacity: 0.7;
  font-family: 'MyCustomFont', sans-serif;
}

.disable-button-middle .label {
  background: linear-gradient(to right, #bf5076, #bf5076);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  opacity: 0.7;
  font-family: 'MyCustomFont', sans-serif;
}

.load-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 12px;
  border-radius: 0px 23px 23px 0px;
  z-index: 999px;
}

.star-count {
  font-size: 15px;
  color: #ffffff;
  padding-left: 2px;
  padding-right: 10px;
}

.ant-drawer-content {
  background-color: transparent;
}

.jinfen-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.jinfen-title {
  font-size: 17;
  color: #ffffff;
  align-self: center;
  margin-top: 20px;
}

.jinfen-close {
  position: absolute;
  top: 20px;
  right: 16px;
}

.jinfen-show-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 18px 16px 0px 16px;
}

.jinfen-show-container-count {
  color: white;
  margin-left: 4px;
}

.jinfen-show-container-count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shengdou-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: hsla(0, 0%, 100%, 0.05);
  padding: 2px 6px 2px 5px;
  border-radius: 23px;
}

.shengdou-container-count {
  color: white;
  margin-left: 4px;
  margin-right: 8px;
}

.jinfen-get-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 16px 16px 16px;
}

.jinfen-item-container:last-child {
  margin-right: 0px;
}

.jinfen-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8484841A;
  padding-top: 8px;
  padding-bottom: 8px;
  flex: 1;
  margin-right: 9px;
  border-radius: 8px;
}

.jinfen-item-active {
  outline: 1px solid #ff3f9c;
}

.jinfen-item-price-text {
  font-size: 14px;
  color: #ffffff;
  padding-top: 8px;
}

.jinfen-item-description-text {
  font-size: 12px;
  color: #979797;
}

.jinfen-button {
  height: 44px;
  margin: 0px 16px;
  border-radius: 8px;
  background: linear-gradient(to right, #cc64e1, #825ced);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
}

.jinfen-button-container {
  margin-top: 10px;
  margin-bottom: 30px;
}

.ant-popover-inner-content {
  padding: 12px;
  border-radius: 8px;
}

.more-item {
  font-size: 14;
  color: #979797;
  padding-bottom: 16px;
}

.more-item:last-child {
  padding-bottom: 0px;
}

.mySwiper {
  // width: 100%;
  height: 22px;
}
// 
.start_box{
  width: 100%;
  display: flex;
  justify-content: center;
}
.start_btn{
width: 160px;
height: 50px;
margin-top: 10px;
}
.swiper_btn{
  background-image: url("../../assets/hua/award_img.png");
  background-size: 260px 120%;
  background-position-x: 50%;
  background-repeat: no-repeat;
}
.wheel_box{
  position: relative;
}
.wheel_box_hidden{
  animation: spin 60s linear infinite;
}

.zhen_box{
  position: absolute;
  top: 0px;
  width: 70px;
  left: 50%;
  margin-left: -35px;
}
.zhen_box_hight{
    position: absolute;
      top: 0px;
      width: 40px;
      left: 50%;
      margin-left: -20px;
}
/* src/styles/fonts.module.css */
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../fonts/jiangchengzhiyinti.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* 定义旋转动画 */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@primary-color: #1DA57A;