.goods-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 0px 24px 0px;
  width: 322px;
  background-color: #ffffff;
}

.goods-modal-title {
  align-self: center;
  text-align: center;
  font-size: 18px;
  color: #050303;
  position: absolute;
  top: -55px;
  width: 100vw;
  font-weight: medium;
}

.goods-modal-title-show {
  align-self: center;
  font-size: 13px;
  color: #F03550;
}

.goods-model-one {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.goods-model-one-img {
  align-self: center;
}

.goods-model-one-text {
  align-self: center;
  padding-top: 8px;
}

.goods-model-one-description {
  align-self: center;
  color: #979797;
  font-size: 12px;
}

.goods-model-one-button {
  background: linear-gradient(to right, #cc64e1, #cc64e1);

  align-self: center;
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;

  font-size: 14px;
  color: #ffffff;
  font-weight: medium;
  margin-top: 32px;
}

.ant-modal-content {
  background: transparent;
}

.goods-modal {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.goods-model-close {
  align-self: center;
  margin-top: 20px;
}

.goods-model-list {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.goods-model-list-button {
  background: #7435fd;

  align-self: center;
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;

  font-size: 14px;
  color: #ffffff;
  font-weight: medium;
  margin-top: 32px;
}

.goods-modal-item {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.goods-model-item-text {
  padding-left: 8px;
}

.goods-modal-item-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  margin-right: 8px;

}

.goods-modal-item-show {
  font-size: 13px;
  color: #F03550;
  margin-right: 8px;

}

.goods-modal-item-left {
  font-size: 15px;
  color: #343537;
}

.ant-modal-body {
  padding: 0 !important;
}
@primary-color: #1DA57A;